<template>
  <course-card-component v-if="is_loading" :coursesData="courses" :edu_year="null" @createCourse="createCourse"
    :permission="permission" />
  <loading v-else />
</template>

<script>
import loading from "@/components/loading/loading.vue";
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent";
import store from "@/store/index";

export default {
  components: {
    loading,
    CourseCardComponent,
  },
  data() {
    return {
      courses: [],
      is_loading: true,
    };
  },
  computed:{
    permission(){
      store.dispatch("GET_PERMISSION", "courses");
        return store.state.userData.sub_Permission;
    }
  },
  beforeMount() {
    this.getAllcourses();
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getAllcourses() {
      try {
        const res = await this.$http.get("/admin/courses?my_courses=true");
        this.courses = res.data.data;
        console.log(res);
        // this.getMyCourses(res.data.data);

      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    createCourse() {
      this.getAllcourses();
    },
  },
};
</script>

<style lang="scss"></style>
